import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { ContainerSVGT, MainFont, LiveColor, ThirdPage} from '../components/Sampler'
import {
  fbliveSVGT1,
    fbliveSVGT2,
    fbliveSVGT3,
    fbliveSVGT4,
    fbliveSVGT5,
    fbliveSVGT6,
    fbliveSVGT7,
  fbvideoSVGT1,
    fbvideoSVGT2,
    fbvideoSVGT3,
    fbvideoSVGT4,
    fbvideoSVGT5,
    fbvideoSVGT6,
    fbvideoSVGT7,
    fbvideoSVGT8
} from '../../static/svgtext'
import HistoryPhoto from '../../static/img/uploads/History Photo.jpg'
import HistoryMap from '../../static/img/uploads/History Map.jpg'
import HistoryNews from '../../static/img/uploads/History Newspaper.jpg'
import { parse } from 'node-html-parser'
import fetch from 'node-fetch'

const YTChannelID = 'UC0BkdMBvCRCypyq_prDodiw'
// Moody Church Channel ID: UC_dIKJHu5K-iVDclhs1GYGQ

var YTLiveActive = false
var YTLiveDay = 0 // 0 = Sunday, 1 = Monday, etc.
var YTLiveStartTime = '15:00' // Needs to be registered in UTC format, which is 4 hours ahead of ETZ 
var YTLiveEndTime = '16:00' // Needs to be registered in UTC format, which is 4 hours ahead of ETZ 
var now = new Date()

function YTLiveCheck() {
  now = new Date()
  if (now.getDay() == YTLiveDay) {

    var NowString = now.getUTCHours() + ':' + now.getUTCMinutes()
    var NowNumber = (Number(NowString.substring(0, 2)) * 60) + Number(NowString.substring(3, 5))
    var StartNumber = (Number(YTLiveStartTime.substring(0, 2)) * 60) + Number(YTLiveStartTime.substring(3, 5))
    var EndNumber = (Number(YTLiveEndTime.substring(0, 2)) * 60) + Number(YTLiveEndTime.substring(3, 5))

    console.log('NowString: ' + NowString + ' || NowNumber: ' + NowNumber + ' || StartNumber: ' + StartNumber + ' || EndNumber: ' + EndNumber)

    if (NowNumber >= StartNumber && NowNumber <= EndNumber) {
      YTLiveActive = true
    } else {
      YTLiveActive = false
    }
  } else {
    YTLiveActive = false
  }

  console.log('YTLiveActive is ' + YTLiveActive)
}


function ytdata() {
  var iframes = document.getElementsByClassName('latestVideoEmbed');
  for (var i = 0, len = iframes.length; i < len; i++) {
    loadVideo(iframes[i])
  }
}
function loadVideo(iframe) {
  const channelURL = encodeURIComponent(`https://www.youtube.com/feeds/videos.xml?channel_id=${YTChannelID}`)
  const reqURL = `https://api.rss2json.com/v1/api.json?rss_url=${channelURL}`
  fetch(reqURL, {method: 'GET', redirect: 'follow'})
    .then(response => response.json())
    .then(result => {
      const videoNumber = (iframe.getAttribute('vnum') ? Number(iframe.getAttribute('vnum')) : 0)
      const link = result.items[videoNumber].link
      const id = link.substr(link.indexOf("=") + 1)
      var date = result.items[videoNumber].pubDate
      var title = result.items[videoNumber].title
      var multiple = {
        '&amp;' : '&',
        '&lt;' : '<',
        '&gt;' : '>',
        '&quot;' : '"',
        '&apos;' : '\''
      };
      for (var char in multiple) {
        var before = char
        var after = multiple[char]
        var pattern = new RegExp(before, 'g')
        title = title.replace(pattern, after)
      }
      date = new Date(date).toLocaleDateString('en-us', { month: "short", day: "numeric", year: "numeric" })
      iframe.setAttribute("src", `https://youtube.com/embed/${id}?controls=1&autoplay=0&modestbranding=1&rel=0`)
      iframe.parentElement.querySelector("#video-title").innerText = title
      iframe.parentElement.querySelector("#video-date").innerText = 'Posted: '+date
    })
    .catch(error => console.log('error', error))
}

export const SermonsPageTemplate = ({ title, backgroundimage, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  const [count, setCount] = useState(1)
  const [more, setMore] = useState(false)

  useEffect(() => {
    ytdata()
  })

  // // // Simple GET request using fetch
  // fetch('https://api.npms.io/v2/search?q=react')
  //   .then(response => response.json())
  //   .then(data => {
  //    console.log(data.total)
  //   });

  return (

    // TITLE BANNER
    <div>

      {/* Font Imports */}
      <link
        href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
        rel="stylesheet"
      ></link>
      <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.cdnfonts.com/css/bahnschrift" rel="stylesheet"></link>

      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: ThirdPage === 'History' ? `url("/img/uploads/History Sample.jpg")` : `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
            })`,
        }}
      >

        {/* LIVE IMAGE HEADING */}
        <div style={{ display: 'grid' }}>

          {/* Colored Style Container */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="310px" viewBox="0 0 1563.000000 1525.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
            }}>

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
              fill={LiveColor} stroke="none">
              <path d={ContainerSVGT} />
            </g>
          </svg>

          {/* "Live" Text */}
          <div style={{
            gridRow: '1 / 1',
            gridColumn: '1 / 1',
            alignSelf: 'center',
            textAlign: 'center',
            fontSize: '3.2em',
            color: '#FFFFFF',
            fontFamily: MainFont,
            fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
            letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
          }}>
            {ThirdPage === "Livestream (Facebook)" || ThirdPage === "Livestream (YouTube)" ? "LIVE" : ThirdPage === "Video (Facebook)" || ThirdPage === "Video (YouTube)" ? "SERMONS" : "HISTORY"}
          </div>
        </div>
      </div>

      {/* Google Font */}
      <link
        href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
        rel="stylesheet"
      ></link>

      {/* LIVESTREAM, YOUTUBE */}
      {ThirdPage === 'Livestream (YouTube)' ? 
      
      <div>
        {YTLiveCheck()}
        
        {YTLiveActive ? 
          <div className='video-box' style={{backgroundColor: LiveColor}}>
              <iframe class='livestreamEmbed' width="560" height="315" src="https://www.youtube.com/embed/live_stream?channel=${YTChannelID}" frameborder="0" allowfullscreen></iframe>
          </div>
        : 
          <div>
            <div className='video-box' style={{backgroundColor: LiveColor, minHeight: '300px'}}>
              <p>Livestreams will appear here between {(YTLiveStartTime.substring(0, 2) - (now.getTimezoneOffset() / 60)) > 12 ? (Number(YTLiveStartTime.substring(0, 2)) - (now.getTimezoneOffset() / 60)) - 12 : (Number(YTLiveStartTime.substring(0, 2)) - (now.getTimezoneOffset() / 60))}:{YTLiveStartTime.substring(3, 5)}{(YTLiveStartTime.substring(0, 2) - (now.getTimezoneOffset() / 60)) > 11 ? 'PM' : 'AM'} and {(YTLiveEndTime.substring(0, 2) - (now.getTimezoneOffset() / 60)) > 12 ? (Number(YTLiveEndTime.substring(0, 2)) - (now.getTimezoneOffset() / 60)) - 12 : (Number(YTLiveEndTime.substring(0, 2)) - (now.getTimezoneOffset() / 60))}:{YTLiveEndTime.substring(3, 5)}{(YTLiveEndTime.substring(0, 2) - (now.getTimezoneOffset() / 60)) > 11 ? 'PM' : 'AM'} on {YTLiveDay == 0 ? 'Sundays' : YTLiveDay == 1 ? 'Mondays' : YTLiveDay == 2 ? 'Tuesdays' : YTLiveDay == 3 ? 'Wednesdays' : YTLiveDay == 4 ? 'Thursdays' : YTLiveDay == 5 ? 'Fridays' : YTLiveDay == 6 ? 'Saturdays' : 'Sundays'} </p>
            </div>
              {more === false ?
                <button className='see-more' style={{ backgroundColor: LiveColor }} onClick={() => setMore(true)}>
                  <div className='see-more-arrow'></div>
                  <p>See More</p>
                </button>
                :
                null
              }

              {more === true ?
                <div>
                  <div className='video-box' style={{ backgroundColor: LiveColor }}>
                    <p className='video-title' id="video-title">Loading videos...</p>
                    <p id="video-date" style={{ marginBottom: '30px' }}></p>
                    <iframe class="latestVideoEmbed" vnum='0' frameborder="0" allowFullScreen></iframe>
                  </div>

                  <div className='video-box' style={{ backgroundColor: LiveColor }}>
                    <p className='video-title' id="video-title">Loading videos...</p>
                    <p id="video-date" style={{ marginBottom: '30px' }}></p>
                    <iframe class="latestVideoEmbed" vnum='1' frameborder="0" allowFullScreen></iframe>
                  </div>

                  <div className='video-box' style={{ backgroundColor: LiveColor }}>
                    <p className='video-title' id="video-title">Loading videos...</p>
                    <p id="video-date" style={{ marginBottom: '30px' }}></p>
                    <iframe class="latestVideoEmbed" vnum='2' frameborder="0" allowFullScreen></iframe>
                  </div>

                  <button className='see-more' style={{ backgroundColor: LiveColor }} onClick={() => setMore(false)}>
                    <div className='see-less-arrow'></div>
                    <p>See Less</p>
                  </button>
                </div>
                :
                null
              }
          </div>
        }
      </div>

      // LIVESTREAM, FACEBOOK
      : ThirdPage === 'Livestream (Facebook)' ? 
      <div>
        
        {/* Livestream Info text */}
        <div style={{
          fontSize: '1.2em',
          lineHeight: '2em',
          letterSpacing: '0.06em',
          fontFamily: 'Poppins',
          color: LiveColor,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '25%',
          marginRight: '25%',
          marginTop: '40px',
          marginBottom: '40px',
          textAlign: 'center',
          }}
        >
          <p><b>Click the button</b> below to jump to our <b>Facebook page</b>, where you can watch <b>live recordings</b> of our services on Sundays</p>
        </div>

        <div style={{
          textAlign: 'center'
        }}>
          <a title="Facebook" href="https://www.facebook.com/316955266494/live_videos/">
            {/* <img
              className="link-button"
              src={FBLiveImg}
              alt="Facebook"
              style={{
                height: '150px',
                justifyContent: 'center',
                marginBottom: '60px'
              }}
            /> */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="374.000000pt" viewBox="0 0 749.000000 374.000000"
            preserveAspectRatio="xMidYMid meet" className="link-button"
            alt="Facebook"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              height: '150px',
              justifyContent: 'center',
              marginBottom: '60px'
            }}>
              <g transform="translate(0.000000,374.000000) scale(0.100000,-0.100000)"
                fill={LiveColor} stroke="none">
                <path d={fbliveSVGT1}/>
                <path d={fbliveSVGT2}/>
                <path d={fbliveSVGT3}/>
                <path d={fbliveSVGT4}/>
                <path d={fbliveSVGT5}/>
                <path d={fbliveSVGT6}/>
                <path d={fbliveSVGT7}/>
              </g>
            </svg>
          </a>
        </div>

        {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: '50px'}}>
          <iframe width="560" height="315" src="https://www.facebook.com/RedBankMarionville/live_videos/" frameborder="0" allowfullscreen></iframe>
        </div> */}
      </div>

      // VIDEO YOUTUBE
      : ThirdPage === 'Video (YouTube)' ?

      <div>
        <div className='video-box' style={{backgroundColor: LiveColor}}>
          <p className='video-title' id="video-title">Loading videos...</p>
          <p id="video-date" style={{marginBottom: '30px'}}></p>
          <iframe class="latestVideoEmbed" vnum='0' frameborder="0" allowFullScreen></iframe>
        </div>

        {more === false ? 
        <button className='see-more' style={{backgroundColor: LiveColor}}onClick={() => setMore(true)}>
          <div className='see-more-arrow'></div>
          <p>See More</p>
        </button>
        :
        null
        }

        {more === true ?
          <div>
            <div className='video-box' style={{ backgroundColor: LiveColor }}>
              <p className='video-title' id="video-title">Loading videos...</p>
              <p id="video-date" style={{ marginBottom: '30px' }}></p>
              <iframe class="latestVideoEmbed" vnum='1' frameborder="0" allowFullScreen></iframe>
            </div>

            <div className='video-box' style={{ backgroundColor: LiveColor }}>
              <p className='video-title' id="video-title">Loading videos...</p>
              <p id="video-date" style={{ marginBottom: '30px' }}></p>
              <iframe class="latestVideoEmbed" vnum='2' frameborder="0" allowFullScreen></iframe>
            </div>

            <div className='video-box' style={{ backgroundColor: LiveColor }}>
              <p className='video-title' id="video-title">Loading videos...</p>
              <p id="video-date" style={{ marginBottom: '30px' }}></p>
              <iframe class="latestVideoEmbed" vnum='3' frameborder="0" allowFullScreen></iframe>
            </div>

            <button className='see-more' style={{backgroundColor: LiveColor}}onClick={() => setMore(false)}>
              <div className='see-less-arrow'></div>
              <p>See Less</p>
            </button>
          </div>
          :
          null
        }
        
        <script>{() => setCount(count + 1)}</script> {/* The count variable is irreleveant. It just forces useEffect to run ytdata() */}
      </div>
    
      // VIDEO FACEBOOK
      : ThirdPage === 'Video (Facebook)' ? 
      <div>
        {/* Video Info text */}
        <div style={{
          fontSize: '1.2em',
          lineHeight: '2em',
          letterSpacing: '0.06em',
          fontFamily: 'Poppins',
          color: LiveColor,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '25%',
          marginRight: '25%',
          marginTop: '40px',
          marginBottom: '40px',
          textAlign: 'center',
        }}
        >
          <p><b>Click the button</b> below to jump to our <b>Facebook page</b>, where you can watch <b>video recordings</b> of our Sunday services</p>
        </div>

        <div style={{
          textAlign: 'center'
        }}>
          <a title="Facebook" href="https://www.facebook.com/RedBankMarionville/videos/">
            {/* <img
              className="link-button"
              src={FBVideoImg}
              alt="Facebook"
              style={{
                height: '150px',
                justifyContent: 'center',
                marginBottom: '60px'
              }}
            /> */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="374.000000pt" viewBox="0 0 749.000000 374.000000"
            preserveAspectRatio="xMidYMid meet" className="link-button"
            alt="Facebook"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              height: '150px',
              justifyContent: 'center',
              marginBottom: '60px'
            }}>
              <g transform="translate(0.000000,374.000000) scale(0.100000,-0.100000)"
                fill={LiveColor} stroke="none">
                <path d={fbvideoSVGT1}/>
                <path d={fbvideoSVGT2}/>
                <path d={fbvideoSVGT3}/>
                <path d={fbvideoSVGT4}/>
                <path d={fbvideoSVGT5}/>
                <path d={fbvideoSVGT6}/>
                <path d={fbvideoSVGT7}/>
                <path d={fbvideoSVGT8}/>
              </g>
            </svg>
          </a>
        </div>

        {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: '50px'}}>
          <iframe width="560" height="315" src="https://www.facebook.com/RedBankMarionville/live_videos/" frameborder="0" allowfullscreen></iframe>
        </div> */}
      </div>

      // HISTORY
      : 
      <div>
        <div class="container">
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <div class="section">
                <h2 class="title is-size-3 has-text-weight-bold is-bold-light">
                  OUR HISTORY
                </h2>
                <div class="content">
                  <p><em>I&#xFEFF;f your church doesn't livestream or record its services, you can use this page to tell visitors about its history instead. You have a lot of flexibility with this. You can make it short or long, add pictures, headings, links, and don't forget - you can change any of it whenever you want!</em></p>
                  <h3>FOUNDERS</h3>
                  <div><img src={HistoryPhoto} alt="" title="John and Jane Doe" data-reactroot=""/></div>
                  <p>Here are some people we'll pretend are your church founders. And here's where you can write information about them. You can tell us why they started the church, or maybe how it went at first. What's the legacy that your church gets to be a part of?</p>
                  <h3>P&#xFEFF;LACES</h3>
                  <div><img src={HistoryMap} alt="" title="" data-reactroot=""/></div>
                  <p>If the history of your church is connected to the history of your town, you could consider showing some old maps and explaining how they both grew alongside each other. Maybe you how an old sketch of the church building when it was being constructed, or maybe you want to talk about the local network of churches that the first pastor started and how you’re still a part of it today.</p>
                  <h3>TIMELINE</h3>
                  <div><img src={HistoryNews} alt="" title="" data-reactroot=""/></div>
                  <p>Whether it's newspaper clippings or your own custom timeline, you could show your website visitors what's happened with the church at key points in its history!</p>
                  <p>R&#xFEFF;eally, you can do so much a history page, it's up to you what you think is important for visitors to know. You're just a couple of clicks and paragraphs away from making it just how you want it. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  )
}

SermonsPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const SermonsPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SermonsPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

SermonsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SermonsPage

export const sermonsPageQuery = graphql`
  query sermonsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`